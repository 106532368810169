// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actualites-tsx": () => import("./../../../src/pages/actualites.tsx" /* webpackChunkName: "component---src-pages-actualites-tsx" */),
  "component---src-pages-coin-expert-tsx": () => import("./../../../src/pages/coin_expert.tsx" /* webpackChunkName: "component---src-pages-coin-expert-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reclamation-tsx": () => import("./../../../src/pages/reclamation.tsx" /* webpackChunkName: "component---src-pages-reclamation-tsx" */),
  "component---src-pages-solutions-strapi-product-name-tsx": () => import("./../../../src/pages/solutions/{StrapiProduct.name}.tsx" /* webpackChunkName: "component---src-pages-solutions-strapi-product-name-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

